import Concerts from '../Concerts'
import { Link } from 'gatsby'
import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'
import routes from '../../routes'
interface SectionConcertProps {
  concerts: readonly GatsbyTypes.ConcertFragment[]
  showOnIndexPage?: boolean
}
const classes = /** class={ */ {
  link: 'text-primary hover:underline text-sm md:text-base text-center',
} /** } */

const getJSONLD = (concerts: readonly GatsbyTypes.ConcertFragment[]): string => {
  const concertsData = concerts.map(concert => {
    return {
      '@type': 'Event',
      name: concert.title,
      location: concert.concertAcf?.localisation,
      startDate: concert.concertAcf?.date,
    }
  })

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'MusicGroup',
    event: concertsData,
    name: fr.groupName,
  })
}

const SectionConcert: React.FunctionComponent<SectionConcertProps> = ({ concerts, showOnIndexPage }) => (
  <Section title={fr.concerts} onlyOne={!showOnIndexPage}>
    <Concerts concerts={concerts} showOnIndexPage={showOnIndexPage} />
    {showOnIndexPage && (
      <div className="mt-6 text-center">
        <Link to={routes.concerts} className={classes.link}>
          {fr.showAllConcerts}
        </Link>
      </div>
    )}
    <script type="application/ld+json">{getJSONLD(concerts)}</script>
  </Section>
)

export default SectionConcert
