import Img, { FluidObject } from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import React from 'react'
import _ from 'lodash'

interface ConcertsProps {
  concerts: readonly GatsbyTypes.ConcertFragment[]
  showOnIndexPage?: boolean
}
const classes = /** class={ */ {
  list: 'list-none max-w-5xl mx-auto flex flex-col items-start md:grid md:gap-1 md:grid-cols-3 md:items-start',
  concert: 'pt-6 px-2',
  title: 'text-2xl md:text-3xl mb-3',
  icon: 'h-5 w-5 mr-3 text-primary',
  poster: 'w-96 mx-auto mb-24',
} /** } */

const Concerts: React.FunctionComponent<ConcertsProps> = ({ concerts, showOnIndexPage }) => {
  const data = useStaticQuery<GatsbyTypes.ConcertPosterQuery>(graphql`
    query ConcertPoster {
      fileName: file(relativePath: { eq: "affiche.png" }) {
        childImageSharp {
          fluid(maxWidth: 507) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Img
        className={classes.poster}
        fluid={data.fileName?.childImageSharp?.fluid as FluidObject}
        alt={'Lux bas-fond logo'}
      />
      <ol className={classes.list}>
        {_.orderBy(
          concerts,
          function (concert) {
            var dateParts = (concert.concertAcf?.date ?? '01/01/2020').split('/')
            var dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
            return dateObject.getTime()
          },
          showOnIndexPage ? 'asc' : 'desc'
        )
          .slice(0, showOnIndexPage ? 3 : concerts.length)
          .map(concert => {
            return (
              <li key={concert.id}>
                <div className={classes.concert}>
                  <header>
                    {showOnIndexPage ? (
                      <h3 className={classes.title}>{concert.title}</h3>
                    ) : (
                      <h2 className={classes.title}>{concert.title}</h2>
                    )}
                    <div className="flex items-center py-2">
                      <svg
                        className={classes.icon}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      {concert.concertAcf?.localisation}
                    </div>
                    <div className="flex items-center py-2">
                      <svg
                        className={classes.icon}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      {concert.concertAcf?.date}
                    </div>
                    {concert.concertAcf?.time && (
                      <div className="flex items-center py-2">
                        <svg
                          className={classes.icon}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        {concert.concertAcf.time}
                      </div>
                    )}
                  </header>
                </div>
              </li>
            )
          })}
      </ol>
    </>
  )
}

export default Concerts

export const ConcertFragment = graphql`
  fragment Concert on WpConcert {
    id
    title
    concertAcf: acf {
      localisation
      date
      time
    }
  }
`
